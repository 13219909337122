import Card from "../components/Card";
import TitleBlock from "../components/TitleBlock";


const Contact = () => {
    return (
        <main className="row">
            <div className="col-12">
                <TitleBlock title="Contact" />
            </div>
        </main>
    );
}

export default Contact;