
const ThemeSwitch = ({link}) => {
    return (
        <div className="float-button">
            <a href={link}>
                <img src="mochi-hello-white.gif" alt="mochi-hello-white" width="86" height="86" />
            </a>
        </div>
    )
}

export default ThemeSwitch