import Card from "../components/Card";
import TitleBlock from "../components/TitleBlock";
import CBC from "../components/CBC";
import {useEffect, useState} from "react";

const Main = ({dataPath, bright, name, intro}) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetch(`/${dataPath}/catalog/articles.json`)
            .then((response) => response.json())
            .then((data) => setArticles(data));
    }, [dataPath]);

    return (
        <main className="row">
            <div className="col-12">
                <TitleBlock title={`Hi, I'm ${name}`} subtitle={intro} bright={bright}/>
                { dataPath === 'yumeng' && <CBC/> }
            </div>
            {articles.map((article) => (
                <Card
                    key={article.id}
                    title={article.title}
                    description={article.description}
                    icon={article.icon}
                    link={article.link}
                    bright={bright}
                />
            ))}
        </main>
    );
}

export default Main;