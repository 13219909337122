

const CBC = () => {
    return (
        <div id="circle-orbit-container">

            <div id="inner-orbit">
                <div className="inner-orbit-circles"></div>
            </div>

            <div id="outer-orbit">
                <div className="outer-orbit-circles"></div>
            </div>

        </div>
    )
}

export default CBC;