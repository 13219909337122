const TitleBlock = ({ title, subtitle, description, bright }) => {
    return (
        <div className={`title-block ${bright? "bright-mode": "dark-mode"}`}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            <p>{description}</p>
        </div>
    );
}


export default TitleBlock;
