import React, { useState, useEffect } from 'react'; // import the useState and useEffect hooks
import Markdown from 'react-markdown'
import {useParams} from "react-router-dom";

const Article = ({dataPath}) => {
    const { file } = useParams(); // use the useParams hook to get the file parameter

    // create a new state variable for the content
    const [content, setContent] = useState('');

    // use the useEffect hook to load the content when the component mounts
    useEffect(() => {
        fetch(`/${dataPath}/articles/${file}.md`)
            .then(response => response.text())
            .then(text => setContent(text)); // update the content state with the loaded content
    }, [file]); // add file as a dependency of the useEffect hook


    return (
        <main className="row">
            <div className="col-12">
                <article className="markdown-body">
                    <Markdown>
                        {content}
                    </Markdown>
                </article>
            </div>
        </main>
    )
}

export default Article;