import React from 'react';
import {Link} from "react-router-dom";

const Index = ({ title, description, icon, bright, link }) => {
    return (
        <div className="col-6">
            <Link to={link}>
                <div className={`card ${bright ? "bright-mode" : "dark-mode"}`}>
                    <img src={icon} alt={`${title} icon`} className="tool-icon"/>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </Link>
        </div>
    );
};

export default Index;
