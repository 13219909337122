import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import {Suspense, useState} from "react";

const Model = ({filename}) => {
    console.log(filename)
    const gltf = useLoader(GLTFLoader, filename);
    return (
        <>
            <primitive object={gltf.scene} scale={4} />
        </>
    );
};

const SWSH = () => {
    const models = [
        { name: '(2,2)', filename: "models/SWSH22.glb" },
        { name: '(2,1)', filename: "models/SWSH21.glb" },
        { name: '(2,0)', filename: "models/SWSH20.glb" },
        { name: '(3,3)', filename: "models/SWSH33.glb" },
        { name: '(4,4)', filename: "models/SWSH44.glb" },
    ]

    // add state model_filename
    const [filename, setFilename] = useState(models[0].filename);

    const handleModelChange = (event) => {
        setFilename(event.target.value);
    }

    return (
        <div>
            <select onChange={handleModelChange}>
                {models.map((model, index) => {
                    return <option key={index} value={model.filename}>{model.name}</option>
                })}
            </select>

            <Canvas>
                <Suspense fallback={null}>
                    <Model filename={filename}/>
                    <OrbitControls/>
                    <Environment preset="forest" background/>
                </Suspense>
            </Canvas>
        </div>

    );
}

export default SWSH;