import TitleBlock from "../components/TitleBlock";
import PhotoAlbum from "react-photo-album";
import { useEffect, useState } from "react";

const Photograph = ({dataPath}) => {
    // load photo.json from public folder public/photo.json with fetch API
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        fetch(`/${dataPath}/catalog/photo.json`)
            .then((response) => response.json())
            .then((data) => setPhotos(data));
    }, []);

    return (
        <main className="row">
            <div className="col-12">
                <TitleBlock title="My Photo Gallery" />
            </div>
            <div className="col-12">
                <PhotoAlbum layout="rows" photos={photos} renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                    <a
                        href={photo.href}
                        style={wrapperStyle}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {renderDefaultPhoto({ wrapped: true })}
                    </a>
                )}/>
            </div>
        </main>
    );
}

export default Photograph;