import {Outlet, useNavigate} from "react-router-dom";
import NavBar from "../components/NavBar";
import ParticleBKG from "../components/ParticleStar";
import ThemeSwitch from "../components/ThemeSwitch";
import ParticleNetwork from "../components/ParticleNetwork";
import {useEffect, useState} from "react";

const Layout = ({theme, link}) => {
    const links_yumeng = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Gallery',
            url: '/gallery'
        },
        {
            title: 'Photos',
            url: '/photos'
        },
        {
            title: 'About',
            url: '/about'
        }
    ];

    const links_wei = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'About',
            url: '/about'
        },
        {
            title: 'Contact',
            url: '/contact'
        }
    ];

    const [links, setLinks] = useState(links_yumeng);

    useEffect(() => {
        if (theme === 'bright') {
            setLinks(links_wei);
        } else {
            setLinks(links_yumeng);
        }
    }, [theme]);

    return (
        <div className="container">
            <ThemeSwitch link={link}/>
            <header>
                <NavBar links={links} bright={theme === 'bright'}/>
            </header>
            <Outlet/>
            {theme === 'bright'? <ParticleNetwork/>: <ParticleBKG/> }
        </div>
    );
}

export default Layout;