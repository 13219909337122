import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";

const Index = ({links, bright}) => {
    const location = useLocation();
    const [isScrolling, setIsScrolling] = useState(false);
    const [activeItem, setActiveItem] = useState(location.pathname); // default active item

    const handleClick = (item) => {
        setActiveItem(item);
    };

    useEffect(() => {
        const handleScroll = () => {
            // When the scroll is more than 50px, set isScrolling to true, otherwise false
            const scrolled = document.documentElement.scrollTop > 80;
            setIsScrolling(scrolled);
        };

        // Event listener for scroll events
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        console.log(location.pathname);
        setActiveItem(location.pathname); // update active item when route changes
    }, [location]);

    return (
        <nav className={`navbar ${isScrolling ? 'scrolled' : ''} ${bright? "bright-mode": "dark-mode"}`}>
            <ul>
                {
                    links.map((link, index) => (
                        <li key={index} className={activeItem === link.url ? 'active' : ''}>
                            <Link to={link.url}>{link.title}</Link>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};

export default Index;
