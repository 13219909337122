import TitleBlock from "../components/TitleBlock";
import SWSH from "../gallery/SWSH";

const Gallery = () => {


    return (
        <main className="row">
            <div className="col-12">
                <TitleBlock title="Gallery" />
            </div>
            <div className="col-12">
                <SWSH />
            </div>
        </main>
    );
}

export default Gallery;