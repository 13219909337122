import React, { useEffect, useState } from 'react';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './styles/app.scss';
import Layout from "./pages/Layout";
import Main from "./pages/Main";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Photograph from "./pages/Photograph";
import Gallery from "./pages/Gallery";
import Article from "./pages/Article";

function App() {
    const isXu = window.location.hostname === 'yumengxu.space'
    const link = isXu? 'http://weizhang.xyz': 'http://yumengxu.space';
    const theme = isXu? 'dark': 'bright';
    const dataPath = isXu? 'yumeng': 'wei';
    const name = isXu? 'Yumeng Xu': 'Wei Zhang';
    const intro = isXu? 'A Gravitational Wave Physicist': 'Complex Systems, Simple Rules';

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout theme={theme} link={link}/>}>
                    <Route index element={<Main dataPath={dataPath} name={name} intro={intro} bright={theme === 'bright'}/>}/>
                    <Route path="about" element={<About/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="photos" element={<Photograph dataPath={dataPath}/>}/>
                    <Route path="gallery" element={<Gallery/>}/>
                    <Route path="article/:file" element={<Article dataPath={dataPath}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
